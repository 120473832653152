import {
  Button,
  Container,
  Divider,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/logo.png";

import { FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { motion, useScroll } from "framer-motion";

const Header = () => {
  window.addEventListener("scroll", () => {
    if (document.documentElement.scrollTop > 50) {
      document.getElementById("header").className = "animated";
    } else {
      document.getElementById("header").className = "not";
    }
  });
  return (
    <VStack width="100%" id="header" zIndex={10}>
      <HStack bgColor="#9a101c" width="100%" p={2}>
        <Container maxW="6xl" width="100%" p={2}>
          <HStack
            width="100%"
            color="white"
            alignItems="center"
            justifyContent="space-between"
          >
            <HStack>
              <HStack gap={1}>
                <FaPhone color="f7b521" />
                <Text fontSize="14px" fontWeight="500">
                  01060649014
                </Text>
              </HStack>

              <Divider
                orientation="vertical"
                height="15px"
                opacity=".5"
                w="1px"
                background="white"
              />
              <HStack gap={1}>
                <MdOutlineEmail color="f7b521" />
                <Text fontSize="14px" fontWeight="500">
                  info@la-mer1.com
                </Text>
              </HStack>
            </HStack>
            <HStack gap={4}>
              <Text
                cursor="pointer"
                onClick={() =>
                  window.location.assign("https://asquera.com/login")
                }
                fontSize="14px"
              >
                Log in
              </Text>
              <Divider
                orientation="vertical"
                height="15px"
                opacity=".5"
                w="1px"
                background="white"
              />
              <Text
                cursor="pointer"
                fontSize="14px"
                onClick={() =>
                  window.location.assign("https://asquera.com/login")
                }
              >
                Register
              </Text>
              <FaXTwitter />
              <FaFacebookF />
              <FaInstagram />
              <FaLinkedinIn />
            </HStack>
          </HStack>
        </Container>
      </HStack>
      <HStack width="100%" bgColor="white" mt="-10px" alignItems="center">
        <Container width="100%" maxW="6xl" alignItems="center">
          <HStack
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            py={2}
          >
            <Image src={Logo} />
            <HStack justifyContent="space-between" w="200px">
              <Text
                color="#696969"
                fontWeight="500"
                fontSize="18px"
                cursor="pointer"
                onClick={() =>
                  window.location.assign("https://asquera.com/login")
                }
              >
                Log in
              </Text>

              <Text
                fontWeight="500"
                fontSize="18px"
                bgColor="#6ec1e4"
                p={2}
                borderRadius="2px"
                color="white"
                w="100px"
                textAlign="center"
                cursor="pointer"
                onClick={() =>
                  window.location.assign("https://asquera.com/login")
                }
              >
                Sign up
              </Text>
            </HStack>
          </HStack>
        </Container>
      </HStack>
    </VStack>
  );
};

export default Header;
