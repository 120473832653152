import {
  Container,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/light-logo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";

const Footer = () => {
  return (
    <Stack
      w="100%"
      style={{
        backgroundImage:
          "radial-gradient(at center center, #921C28CC 0%, #921C28 100%)",
      }}
      py={4}
    >
      <Container maxW="6xl" width="100%" alignItems="start">
        <HStack
          flexWrap="wrap"
          w="100%"
          justifyContent="space-between"
          alignItems="start"
        >
          <VStack alignItems="start">
            <Text
              m="0"
              fontSize="16px"
              fontWeight="700"
              lineHeight="30px"
              color="#FFFFFF"
            >
              About
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              About us
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Courses
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Contact us
            </Text>
          </VStack>
          <VStack alignItems="start">
            <Text
              m="0"
              fontSize="16px"
              fontWeight="700"
              lineHeight="30px"
              color="#FFFFFF"
            >
              Links
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Dashboard
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              First Level
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Second Level
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Third Level
            </Text>
          </VStack>
          <VStack alignItems="start">
            <Text
              m="0"
              fontSize="16px"
              fontWeight="700"
              lineHeight="30px"
              color="#FFFFFF"
            >
              Support
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Facebook
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Youtube
            </Text>
            <Text fontSize="14px" fontWeight="500" color="#FFFFFFB3" m="0">
              Telegram
            </Text>
          </VStack>
          <VStack gap={8}>
            <Image src={Logo} />
            <HStack gap={6}>
              <FaFacebookF color="white" fontSize="20px" />
              <FaTelegram color="white" fontSize="20px" />
              <FaYoutube color="white" fontSize="20px" />
            </HStack>
          </VStack>
        </HStack>
      </Container>
    </Stack>
  );
};

export default Footer;
