import {
  Button,
  Container,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Section4 from "../assets/section-4.png";
const Bank = () => {
  return (
    <Container maxW="6xl" width="100%">
      <HStack w="100%" justifyContent="space-between">
        <VStack HStack w="45%" gap={6}>
          <Text color="#05498D" fontSize="34px" m="0" fontWeight="500">
            Questions Bank
          </Text>
          <Text color="#9DAFBD" textAlign="center">
            Test your understanding of previous lessons and review your syllabus
            with the question bank
            <br />
            More than 15,000 questions covering the entire syllabus and making
            you ready for the exam at any time
          </Text>
          <HStack justifyContent="space-between" w="70%">
            <Button
              bgColor="#ce1626"
              color="white"
              w="150px"
              h="60px"
              borderRadius="4px"
              fontSize="15px"
              fontWeight="700"
            >
              Enroll Now
            </Button>{" "}
            <Button
              bgColor="#ce1626"
              color="white"
              w="150px"
              h="60px"
              borderRadius="4px"
              fontSize="15px"
              fontWeight="700"
            >
              Start Quiz
            </Button>
          </HStack>
        </VStack>
        <Image src={Section4} w="45%" />
      </HStack>
    </Container>
  );
};

export default Bank;
