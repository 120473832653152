import {
  Button,
  Container,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Teacher from "../assets/teacher.png";
const Aboutus = () => {
  return (
    <HStack
      width="100%"
      style={{
        backgroundImage:
          "radial-gradient(at center center, #0860A8 0%, #0A1152 100%)",
        transition:
          "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
      }}
    >
      <Container maxW="6xl" width="100%">
        <HStack width="100%">
          <Image src={Teacher} width="50%" />
          <VStack alignItems="start" gap={4}>
            <Text
              fontSize="34px"
              lineHeight="1"
              color="white"
              fontWeight="500"
              m={0}
            >
              <span style={{ color: "#0071dc" }}>Lamer </span>
              was founded by Monsieur
              <br />
              Abdo Farag
            </Text>
            <Text color="white" fontSize="14px" lineHeight="1.67em">
              An expert teacher in the French language for all secondary levels
              <br />
              More than 19 years of experience in the field of teaching in
              public and private schools, and the owner and author of the book
              “Spirituality in the French Language”
            </Text>
            <Button
              bgColor="#CE1626"
              color="white"
              w="150px"
              h="60px"
              borderRadius="4px"
              fontSize="15px"
              fontWeight="700"
            >
              Learn More
            </Button>
          </VStack>
        </HStack>
      </Container>
    </HStack>
  );
};

export default Aboutus;
