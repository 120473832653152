import { Box, Container, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import Student1 from "../assets/student-1.jpg";
import Student2 from "../assets/student-2.jpg";
import Student3 from "../assets/student-3.png";
import Student4 from "../assets/student-4.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Students = () => {
  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    slidesToScroll: 3,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container maxW="6xl" width="100%">
      <Stack width="100%" my={16}>
        <Text
          color="#05498D"
          fontSize="34px"
          m="0"
          fontWeight="500"
          textAlign="center"
        >
          Students Gallery
        </Text>
        <Slider {...settings}>
          <Image src={Student1} width="400px" h="200px" p={4} />

          <Image src={Student2} width="400px" h="200px" p={4} />

          <Image src={Student3} width="400px" h="200px" p={4} />

          <Image src={Student4} width="400px" h="200px" p={4} />
        </Slider>
      </Stack>
    </Container>
  );
};

export default Students;
