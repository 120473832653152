import React from "react";
import HeaderImg from "../assets/header-1.png";
import Whyus from "./whyus";
import {
  Button,
  Container,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
const Banner = () => {
  return (
    <VStack
      mt="-10px"
      w="100%"
      style={{
        backgroundImage:
          "radial-gradient(at center center, #0860A8 0%, #0A1152 100%)",
        transition:
          "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
      }}
    >
      <Container maxW="6xl" width="100%">
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <VStack alignItems="start" position="relative">
            <Text
              fontSize="40px"
              color="white"
              fontWeight="bold"
              lineHeight="1.4em"
              m="0"
            >
              Explore more with
              <br />
              <span className="mark">La Mer </span>
              and enjoy important
              <br />
              language skills with{" "}
              <span className="mark">
                full
                <br />
                grade
              </span>
            </Text>
            <Text color="#9DAFBD" fontSize="18px" lineHeight="1.67em">
              Learning French? it won’t be a problem ! Are you ready to be a
              Parisian student?
            </Text>
            <Button
              bgColor="#ce1626"
              color="white"
              w="150px"
              h="60px"
              borderRadius="4px"
              fontSize="15px"
              fontWeight="700"
            >
              Start Now
            </Button>
          </VStack>
          <Image src={HeaderImg} />
        </HStack>
      </Container>
      <Whyus />
    </VStack>
  );
};

export default Banner;
