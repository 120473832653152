import { Container, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Section from "../assets/section-5.png";
const Wallet = () => {
  return (
    <VStack
      py={8}
      w="100%"
      style={{
        backgroundImage:
          "radial-gradient(at center center, #0860A8 0%, #0A1152 100%)",
        transition:
          "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
      }}
    >
      <Container maxW="6xl" width="100%">
        <VStack w="100%">
          <Text color="white" fontSize="34px" m="0" fontWeight="500">
            Student Wallet
          </Text>
          <Text
            color="white"
            textAlign="center"
            lineHeight="1.86"
            fontSize="14px"
          >
            A system where the student places an amount of money on the site and
            can study and watch explanatory videos and a question bank through
            it.
            <br />
            The advantage of this system is that it allows the student to review
            at any time without the need to pay each time studying or reviewing
          </Text>

          <HStack justifyContent="center" w="100%">
            <Image src={Section} h="500px" />
            <Text
              color="white"
              fontSize="38px"
              fontWeight="700"
              lineHeight="1.3"
              letterSpacing="0"
              textAlign="center"
            >
              Get 30 LE in your wallet for
              <br />
              every friend you refer to la-
              <br />
              mer​
            </Text>
          </HStack>
        </VStack>
      </Container>
    </VStack>
  );
};

export default Wallet;
