import { useState } from "react";

import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";
import { ChakraProvider, VStack } from "@chakra-ui/react";
import Level from "./components/level";
import Aboutus from "./components/aboutus";
import Bank from "./components/bank";
import Wallet from "./components/wallet";
import Students from "./components/students";
import Banner from "./components/banner";
function App() {
  return (
    <ChakraProvider>
      <VStack w="100%" gap={4}>
        <Header />
        <Banner />
        <Level />
        <Aboutus />
        <Bank />
        <Wallet />
        <Students />
        <Footer />
      </VStack>
    </ChakraProvider>
  );
}

export default App;
