import { Box, Container, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Border from "../assets/border.png";
import icon1 from "../assets/icon-1.png";
import icon2 from "../assets/icon-2.png";
import icon3 from "../assets/icon-3.png";
import icon4 from "../assets/icon-4.png";

import Shape from "../assets/shape.svg";
const Whyus = () => {
  return (
    <VStack w="100%" position="relative">
      <Container maxW="6xl" width="100%">
        <VStack w="100%" position="relative">
          <Text color="#FCFAFA" fontSize="34px">
            Why La Mer
          </Text>
          <Stack
            width="100%"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Stack
              alignItems="center"
              background="white"
              width="23.5%"
              bgColor="#EEF2F4"
              py={4}
            >
              <Image src={icon1} width="36px" />

              <Text color="#026fc2" fontSize="20px" textAlign="center">
                <span style={{ fontWeight: "700" }}>+1000 </span> watched videos
              </Text>
            </Stack>{" "}
            <Stack
              alignItems="center"
              background="white"
              width="23.5%"
              bgColor="#EEF2F4"
              py={4}
            >
              <Image src={icon2} width="36px" />

              <Text color="#026fc2" fontSize="20px" textAlign="center">
                <span style={{ fontWeight: "700" }}>19 </span> Year of Experince
              </Text>
            </Stack>{" "}
            <Stack
              alignItems="center"
              background="white"
              width="23.5%"
              bgColor="#EEF2F4"
              py={4}
            >
              <Image src={icon3} width="36px" />

              <Text color="#026fc2" fontSize="20px" textAlign="center">
                <span style={{ fontWeight: "700" }}>15000 </span> Questions
              </Text>
            </Stack>{" "}
            <Stack
              alignItems="center"
              background="white"
              width="23.5%"
              bgColor="#EEF2F4"
              py={4}
            >
              <Image src={icon4} width="36px" />

              <Text color="#026fc2" fontSize="20px" textAlign="center">
                <span style={{ fontWeight: "700" }}>+1500 </span> Students
              </Text>
            </Stack>
          </Stack>
          <Image src={Border} width="100%" position="relative" zIndex="2" />
        </VStack>
      </Container>
      <Box
        width="100%"
        style={{
          transform: "rotate(180deg)",
          overflow: "hidden",
          position: "absolute",
          left: "0",
          width: "100%",
          lineHeight: "0",
          direction: "ltr",
          zIndex: "1",
          bottom: "-1px",
        }}
      >
        <Image
          src={Shape}
          width="100%"
          style={{
            // width: "calc(100% + 1.3px)",
            height: "232px",

            // transform: "translateX(-50%) rotateY(180deg)",
          }}
        />
      </Box>
    </VStack>
  );
};

export default Whyus;
