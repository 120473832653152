import { Box, Container, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Bg from "../assets/section-3.png";
import Level1 from "../assets/level-1.png";
import Level2 from "../assets/level-2.png";
import Level3 from "../assets/level-3.png";

const Level = () => {
  return (
    <VStack position="relative" w="100%" my={8}>
      <Box position="absolute" right="0" width="100%" height="100%" zIndex="-1">
        <Image
          src={Bg}
          width="100%"
          height="100%"
          objectFit="contain"
          objectPosition="right"
        />
      </Box>
      <Container maxW="6xl" width="100%">
        <VStack w="100%" gap={6}>
          <Text color="#05498D" fontSize="34px">
            Choose Your Level
          </Text>
          <HStack w="100%" flexWrap="wrap" justifyContent="space-between">
            <Image src={Level1} objectFit="cover" w="30%" />
            <Image src={Level2} objectFit="cover" w="30%" />
            <Image src={Level3} objectFit="cover" w="30%" />
          </HStack>
        </VStack>
      </Container>
    </VStack>
  );
};

export default Level;
